.layout-container, .publisher-container {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
  
.layout-container {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
}
  
.layout-container > div {
  background-color: #000;
  display: inline-block;
  border: 1px solid#222;
  transition-property: all;
  transition-duration: 0.5s;    
  width: 0;
  height: 0;
  opacity: 0;
  position: relative;
}

.layout-container > div.details {

}
  
.layout-container > div.ot-layout {
  opacity: 1;
}
.screen-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  display: none !important;
}